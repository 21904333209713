.document-wrap {
  padding: 5rem 5rem 0 5rem;
  background-color: rgba(255, 255, 255, 0.1);
}
.year {
  display: inline-block;
  margin-left: 5rem;
  margin-top: 0;
  font-family: "helvetica";
  font-size: 45px;
  color: white;
  opacity: 0.6;
  font-weight: 100;
  margin-bottom: 0;
}
.big-year {
  font-size: 15em;
  margin: 0px 0px 0px 100px;
  color: rgba(255,255,255,0.10);
  position: absolute;
  top: 0;
}
.author {
  display: inline-block;
  margin-left: 3rem;
  display: inline-block;
  font-family: helvetica;
  font-size: 20px;
  color: #fff;
  font-weight: 100;
}
.author-no-summary {
  display: block;
  margin: 0;
  text-align: center;
  font-size: 45px;
}
@media screen and (max-width: 1024px) {
  .author-no-summary {
    font-size: 1.5em;
  }
}
.link-to-doc {
  display: block;
  text-align: center;
  text-decoration: none;
  font-family: "helvetica";
  color: white;
  opacity: 0.6;
  font-weight: 100;
  margin-bottom: 100px;
}
.thumbnail {
  height: 30%;
  width: 40%;
  float: left;
  margin: 10px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}
.summary {
  background-color: white;
  color: #333;
  border: 1px solid black;
  border-radius: 2px;
  padding: 40px 70px;
  margin: 1% 5% 7% 5%;
  font-size: 19px;
  box-shadow: 10px 10px 0 rgba(136, 136, 136, 0.59);
}
@media screen and (max-width: 768px) {
  .summary {
    margin-bottom: 100px;
    padding: 50px 15px;
  }
}
.excerpts {
  margin-left: 5rem;
  margin-bottom: 0;
  color: white;
  font-family: "helvetica";
  font-size: 45px;
  opacity: 0.6;
  font-weight: 100;
}
.main-image {
  width: 100%;
}
.document-images {
  padding: 75px;
  margin-bottom: 50px;
  background-image: url("/assets/images/textured_paper.png");
  border-radius: 2px;
  margin-left: 5%;
  margin-right: 5%;
  box-shadow: 10px 10px 0 rgba(136, 136, 136, 0.59);
}
@media screen and (max-width: 768px) {
  .document-images {
    box-shadow: none;
  }
}
.right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 250px;
  flex: none;
}
.left {
  flex: 1;
  min-width: 400px;
}
.right img {
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.right img:hover {
  -webkit-transform: scale(1.3);
	transform: scale(1.3);
}
.citation-heading {
  padding-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .document-wrap {
    padding: 2rem .5rem 0 .5rem;
  }
  .page-title {
    margin-left: 0;
    font-size: 1.5rem;
  }
  .year {
    display: inline;
    margin: 0;
    text-align: center;
  }
  .author {
    margin-left: 0;
    text-align: center;
  }
  .document-images {
    padding: 0;
    background-image: none;
  }
  .left {
    display: none;
  }
  .right {
    display: flex;
    flex-direction: column;
    flex: none;
    width: 100%;
  }
  .right img {
    width: 100%;
    margin-bottom: 100px;
  }
  .right img:hover {
    -webkit-transform: scale(1.0);
  	transform: scale(1.0);
  }
  .thumbnail {
    float: none;
    height: 800px;
  }
  .excerpts {
    margin-left: 0;
  }
  .big-year {
    display: none;
  }
}
